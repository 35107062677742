// extracted by mini-css-extract-plugin
export var ArtistDescription = "KoolKoor-module--ArtistDescription--JYZOL";
export var ArtistInfos = "KoolKoor-module--ArtistInfos--4t3g1";
export var ButtonWrapper = "KoolKoor-module--ButtonWrapper --j8FqP";
export var CardWrapper = "KoolKoor-module--CardWrapper--zC1cH";
export var CarrouselWrapper2 = "KoolKoor-module--CarrouselWrapper2--EX7mb";
export var Citations = "KoolKoor-module--Citations--BBkMA";
export var DescriptionWrapper = "KoolKoor-module--DescriptionWrapper--KPg-G";
export var ImageWrapper = "KoolKoor-module--ImageWrapper--C0iDE";
export var LinkWrapper = "KoolKoor-module--LinkWrapper--ex6iH";
export var MobileProtrait = "KoolKoor-module--MobileProtrait--RBXc8";
export var More = "KoolKoor-module--More--Oq9Oq";
export var MoreButton = "KoolKoor-module--MoreButton--SnumU";
export var NameWrapper = "KoolKoor-module--NameWrapper--fHm-I";
export var PdpWrapper = "KoolKoor-module--PdpWrapper--BT1E3";
export var PhotosWrapper = "KoolKoor-module--PhotosWrapper--s6NCk";
export var ProfilWrapper = "KoolKoor-module--ProfilWrapper--cFXUw";
export var TitleWrapper = "KoolKoor-module--TitleWrapper--U7K0+";
export var Wrapper = "KoolKoor-module--Wrapper--HFDPE";